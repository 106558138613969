
/**
 * data request
 * 
 */
export const filteringTime = (time) => {
    const minuteTime = 60;
    const dayTime = 60 * 60 * 24;
    let longTime = 0;
    switch (time) {
      case '1D':
        longTime = dayTime * 1;
        break;
      case '1W':
        longTime = dayTime * 7;
        break;
      case '1M':
        longTime = dayTime * 30;
        break;
      default:
        longTime = parseInt(time) * minuteTime;
        break;
    }
    return longTime;
}
  
