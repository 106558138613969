import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import { base_url } from '@/request/api.js'

let baseUrl = base_url + '/api/'
const service = axios.create({
    baseURL: baseUrl,
	// baseURL: 'http://154.31.0.56:606/api/',
    headers: {
        "Content-Type": "application/json",
    }
})

service.interceptors.request.use(
    config => {
        // Do something before request is sent
        config.headers['lang'] = localStorage.getItem('lang') || 'en'
        const token = localStorage.getItem('token');
        if(token) {
            // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config
    },
    error => {
        // Do something with request error 
        //   console.log(error) // for debug
        Promise.reject(error)
    }
)

service.interceptors.response.use(response => {
    if (response.status !== 200) {
        return Promise.reject(response)
    } else {
        return response.data
    }
},
    error => {
        if (error.response) {

            if (error.response.status === 401) {
                store.commit('logout')
                return Promise.reject(error)
            }
            if (error.response.data && error.response.data.message) {
                // switch (error.response.data.code){
                //     case 4001:
                //         error.response.data.message = i18n.t("PAY_PASSWORD_NOT_EXISTS")
                //         break
                //     case 4004:
                //         error.response.data.message = i18n.t("BALANCE_NOT_ENOUGH")
                //         break
                //     case 4005:
                //         error.response.data.message = i18n.t("COIN_NOT_WITHDRAW")
                //         break
                //     case 4006:
                //         error.response.data.message = i18n.t("NUMBER_NOT_ENOUGH")
                //         break
                //     case 4011:
                //         error.response.data.message = i18n.t("HAS_NOT_FINISH_ORDER")
                //         break
                //     case 4012:
                //         error.response.data.message = i18n.t("WITHDRAW_NOT_EXISTS")
                //         break
                //     case 4013:
                //         error.response.data.message = i18n.t("WITHDRAW_DISABLE_CANCEL")
                //         break
                // }

                // Message.error(error.response.data.message, { offset: 50 });
            }
        }
        return Promise.reject(error)
    })

export default service;
